<app-nav></app-nav>

<div class="container">
    <div class="services-container">
        <div class="heading">
            <img src="assets/icons/message-square-list-svgrepo-com.svg" alt="">
            <div>
                Contact Us
            </div>
        </div>
        <div class="info">
            <img class="services-image" src="assets/images/customer-service-communication.jpg" alt="">
            <div class="text">
                <h2>Need a Help?</h2>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident minima sapiente enim maiores deleniti esse at magni, eveniet, laboriosam ipsum perspiciatis sunt animi harum sit, architecto reprehenderit. Hic, saepe fuga. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam sapiente deserunt porro ducimus, numquam eius aspernatur fugiat nemo voluptas, laborum et architecto asperiores nihil! Esse autem possimus magni earum recusandae?
            </div>
        </div>

    </div>
</div>
<app-footer></app-footer>